.member {
    &_tab {

        // padding-bottom: 30px;
        li {
            margin-left: 38px;

            &:first-child {
                margin-left: 0;
            }

            a {
                color: #979797;

                &.active {
                    color: $main-deep;
                }
            }
        }

        &-box {
            width: calc(100% - 300px);
        }
    }

    &_content {
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
    }

    &_page {
        padding-bottom: 100px;

        &.cart {
            padding-left: 25px;

            .member_content {
                padding-right: 55px;
            }
        }

        &.lesson {
            padding-top: 12px;

            .filter {
                border-radius: 10px;
                margin-top: 35px;
            }

            .member_content {
                padding-bottom: 0;
                margin-bottom: 30px;

                .row {
                    margin-left: -12px;
                    margin-right: -12px;

                    >div {
                        padding-left: 12px;
                        padding-right: 12px;
                        margin-bottom: 30px;
                    }
                }

                .lessonItem_price {
                    margin-right: 5px;
                }

                .lessonItem_time {
                    margin: 0;
                }

                .lessonItem_title {
                    margin-bottom: 5px;
                }

                .lessonItem_btn {
                    right: 20px;
                    bottom: 20px;

                    .btn-icon {
                        width: 30px;
                        height: 30px;
                        border-radius: 5px;
                        cursor: pointer;

                        img {
                            width: 10px;
                        }
                    }
                }

                .lessonItem {
                    >a {
                        padding: 20px;
                    }
                }
            }
        }

        &.page-order {
            .member_content {
                padding-top: 0;
                padding-bottom: 0;
                border-top: 0;
            }

            .account_setting {
                &:first-child {
                    border: 0;
                    padding-top: 0;
                }
            }

            .input-common,
            .input {
                background-color: #eee;
            }
        }

        &.page {
            .member_nav {
                margin-top: 45px;
            }

            .member_content {
                padding-top: 45px;
                padding-bottom: 45px;
            }

            .pagination {
                margin-top: 30px;
            }
        }

        &.account {
            .member_content {
                border-top: 0;
                padding: 0;
            }

            .btn-send {
                width: 190px;
                margin-top: 50px;
                justify-content: center;
                margin: 50px auto 0;
                color: $main-deep;
            }
        }

        &.discount {
            .row {
                margin-left: -23px;
                margin-right: -23px;

                >div {
                    padding-left: 23px;
                    padding-right: 23px;
                    margin-bottom: 30px;
                }
            }
        }
    }

    &_nav {
        padding: 34px 10px;
        border: 1px solid #000;
        border-radius: 10px;

        ul {
            width: fit-content;
            margin: 0 auto;
            transform: translateX(-5px);

            li {
                &:not(:last-child) {
                    margin-bottom: 33px;
                }
            }
        }

        img {
            width: 33px;
        }

        span {
            font-size: 18px;
            margin-left: 20px;
            color: $gray-97;
        }

        a.active {
            span {
                color: #000;
            }
        }
    }

    &_empty {
        padding-top: 200px;
        padding-bottom: 200px;

        img {
            width: 130px;
            display: block;
            margin: 0 auto 30px;
        }

        p {
            text-align: center;
            font-size: 28px;
            margin: 0;
            color: $main-deep;
        }
    }

    &_discount {
        background-color: #B9AC91;
        padding: 8px;

        &_inner {
            background-color: #807569;
            display: flex;
            // align-items: center;
            // align-content: stretch;
        }

        &_left {
            width: calc((100% - 7px) * 0.54);
            padding: 20px 35px 10px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
        }

        &_pic {
            width: 100%;
            padding-bottom: 56%;
            position: relative;
            margin-bottom: 5px;
            box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }

        &_list {
            width: 100%;

            li {
                margin-bottom: 3px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &_listTitle {
            font-size: 14px;
            color: #eee;
        }

        &_listContent {
            font-size: 14px;
            font-weight: 600;
            color: $main-color;
        }

        &_line {
            width: 7px;
            padding: 5px 0;

            // height: 100%;
            &_inner {
                width: 100%;
                height: 100%;
                background-image: url(../img/dicount_dot.svg);
                background-size: contain;
            }
        }

        &_right {
            width: calc((100% - 7px) * 0.46);
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;

            .btn-use {
                margin: 10px auto 0;
            }
        }

        &_main {
            width: 100%;

            &_head {
                width: 43px;
                display: block;
                margin: 0 auto;
            }
        }

        &_name {
            position: relative;
            padding: 15px 0;
            width: 150px;
            margin: 0 auto;

            &_line {
                width: 100%;
                height: 1px;
                background-color: #fff;
                margin: 0 auto;
            }

            h3 {
                margin: 0;
                top: 50%;
                left: 50%;
                font-size: 23px;
                font-weight: bold;
                color: $main-color;
                transform: translate(-50%, -50%);
                padding: 0 5px;
                background-color: #807569;
                width: fit-content;
                position: absolute;
                white-space: nowrap;
            }
        }

        &_title {
            width: 150px;
            text-align: center;
            margin: 0 auto 8px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            position: relative;

            &::before {
                position: absolute;
                content: "";
                width: 1px;
                height: 47px;
                background-color: #fff;
                top: -15px;
                left: 0;
            }

            &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 47px;
                background-color: #fff;
                top: -15px;
                right: 0;
            }
        }

        &_en {
            width: 150px;
            // text-align: center;
            // margin: 0 auto;
            position: relative;
            margin: 0 auto 5px;

            &_line {
                width: 100%;
                height: 1px;
                background-color: #fff;
                margin: 0 auto;
            }

            p {
                margin: 0;
                top: -9px;
                left: 50%;
                transform: translateX(-50%);
                padding: 0 5px;
                background-color: #807569;
                width: fit-content;
                position: absolute;
                font-size: 12px;
                font-weight: 500;
                color: $main-color;
                white-space: nowrap;
            }
        }

        &_price {
            width: 150px;
            text-align: center;
            margin: 0 auto;
            font-size: 23px;
            font-weight: 600;
            color: $main-color;
            border-bottom: 1px solid #fff;
            padding-bottom: 5px;
            position: relative;

            &::before {
                position: absolute;
                content: "";
                width: 1px;
                height: 47px;
                background-color: #fff;
                top: -7px;
                left: 0;
            }

            &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 47px;
                background-color: #fff;
                top: -7px;
                right: 0;
            }
        }
    }

    @media screen and (max-width: 1199px) {
        &_page {
            &.cart {
                padding-left: 0;

                .member_content {
                    padding-right: 0;
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        &_page {
            &.cart {
                .member_content {
                    padding: 0 0 52px;
                    border: 0;
                }
            }

            &.lesson {
                .member_content {
                    border-top: 0 solid #000;
                }
            }

            &.page {
                .member_content {
                    border: 0;
                    padding: 0;
                }
            }

            &.account {
                .member_content {
                    border-bottom: 1px solid #000;
                }
            }
        }

        &_nav {
            padding: 10px;

            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 33.3333%;
                    margin: 15px 0 !important;

                    a {
                        justify-content: center;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        &_page {
            &.lesson {
                .lessonItem_pic {
                    margin-bottom: 0;
                }

                .lessonItem_finish {
                    margin-top: 25px;
                }
            }

            &.discount {
                .row {
                    margin-left: 0;
                    margin-right: 0;

                    >div {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }

        &_nav {
            img {
                width: 23px;
            }

            span {
                font-size: 16px;
                margin-left: 4px;
            }
        }

        &_discount {
            &_right {
                .btn-use {
                    img {
                        margin-right: 10px;
                    }

                    span {
                        display: block;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 500px) {
        &_discount {
            &_left {
                padding: 13px 20px 10px;
            }
        }
    }

    @media screen and (max-width: 450px) {
        &_discount {
            &_left {
                padding: 13px 10px 10px;
                width: calc((100% - 5px) * 0.5);
            }

            &_line {
                width: 5px;
            }

            &_right {
                width: calc((100% - 5px) * 0.5);
            }

            &_name {
                width: 100%;
            }

            &_title {
                width: 100%;
            }

            &_en {
                width: 100%;
            }

            &_price {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 360px) {
        &_nav {
            img {
                width: 20px;
            }

            span {
                font-size: 14px;
                margin-left: 4px;
            }
        }

        &_discount {
            &_list {
                li {
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.account {
    &_setting {
        padding: 50px 20px;
        border-top: 1px solid #000;

        h2 {
            font-size: 20px;
            margin: 0 0 30px;
        }

        &_input {
            display: flex;
            align-items: center;
            height: 50px;
            margin-bottom: 30px;

            label {
                width: 90px;
                position: relative;

                .lebel_remark {
                    position: absolute;
                    left: 0;
                    top: 100%;
                    color: $gray-97;
                    font-size: 14px;
                }
            }

            &_right {
                width: calc(100% - 90px);
                height: 100%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                &.birthday {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .input {
                        &.year {
                            width: 50%;
                        }

                        &.month {
                            width: 40%;
                        }
                    }
                }

                input {
                    height: 100%;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.receiver_adress {
                height: auto;
                align-items: flex-start;

                label {
                    margin-top: 15px;
                }

                .input-common {
                    height: 50px;
                    margin-top: 18px;
                }

                .row {
                    margin-left: -4px;
                    margin-right: -4px;
                    width: 100%;

                    >div {
                        padding-left: 4px;
                        padding-right: 4px;
                    }
                }
            }
        }

        &.interest {
            padding-bottom: 70px;

            .check {
                margin-bottom: 25px;
            }
        }

        &.term {
            border-bottom: 1px solid #000;
            padding: 30px 0;

            a {
                color: #3771C8;
                text-decoration: underline;
            }
        }

        @media screen and (max-width: 991px) {
            &.basic {
                padding-top: 0;
                border-top: 0;
            }

            &_input {
                &.gender {
                    margin-bottom: 20px !important;
                }
            }
        }
    }

    &_btn {
        padding-top: 50px;

        button {
            padding: 12px 65px;
            margin: 0 7px;
            font-size: 16px;
        }

        p {
            margin: 18px 0 0;
            text-align: center;
        }
    }

    &_notify {
        font-size: 20px;
        text-align: center;

        .highlight {
            color: #F10045;
        }

        @media screen and (max-width: 991px) {
            border-top: 0;
        }
    }

    &_manage {
        &_content {
            margin-top: 35px;
            max-width: 750px;
            width: 100%;
            margin: 35px auto 0;
        }

        &_item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &_left {
            width: calc(100% - 120px);
        }

        &_btn {
            width: 120px;

            button {
                width: 100%;
                display: flex;
                justify-content: center;

                &.not {
                    img {
                        opacity: 0.3;
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            &_btn {
                button {
                    img {
                        margin-right: 8px;
                    }

                    span {
                        display: block;
                    }
                }
            }
        }

        @media screen and (max-width: 575px) {
            &_item {
                display: block;
                padding: 20px 25px;
            }

            &_left {
                width: 100%;
                margin-bottom: 15px;
            }

            &_btn {
                width: 100%;

                button {
                    height: 42px;
                }
            }
        }
    }
}

.check {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 28px;

    &_box {
        width: 19px;
        height: 19px;
        border-radius: 5px;
        border: 1px solid #979797;
        transition: $transition-main;
        margin-right: 10px;
    }

    &.active {
        .check_box {
            background-color: $main-color;
            border-color: $main-color;
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

@media screen and (max-width:1200px) {
    .account {
        &_setting {
            &_input {
                &.birthday {
                    height: auto;
                    align-items: flex-start;

                    label {
                        margin-top: 10px;
                    }
                }

                &_right {
                    &.birthday {
                        .input {
                            width: 100% !important;

                            &.year {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:991px) {
    .member {
        &_tab {
            &-box {
                width: calc(100% - 250px);
            }
        }
    }

    .account {
        &_setting {
            &_input {
                &.birthday {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .member {
        &_tab {
            &-box {
                width: 100%;
                padding: 12px 0;
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                margin-top: 34px;
            }
        }

    }

    .account {
        &_setting {
            &.basic {
                border-top: 0;
            }
        }
    }
}

@media screen and (max-width:575px) {
    .member {
        &_tab {
            justify-content: center;

            li {
                margin-left: 19px;
            }

        }
    }

    .account {
        &_setting {
            padding-left: 0;
            padding-right: 0;

            &_input {
                &.gender {
                    align-items: flex-start;

                    .account_setting_input_right {
                        .check {
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            &.term {
                padding: 22px 0;
            }
        }

        &_btn {
            padding-top: 40px;

            button {
                padding: 12px 30px;
                margin: 0 5px;
            }
        }
    }

    .check {
        margin-right: 28px;
    }
}

.cart_order {
    padding-left: 50px;

    @media screen and (max-width: 1199px) {
        padding-left: 0;
    }
}

.order {
    border: 1px solid #000;
    border-radius: 10px;

    h5 {
        padding: 15px;
        text-align: center;
        background-color: #E6E6E6;
        font-size: 20px;
        color: $main-deep;
        margin-bottom: 25px;
        border-radius: 10px 10px 0 0;
        font-weight: normal;
        letter-spacing: 0.05em;
    }

    &_list {
        color: $main-deep;
        font-size: 18px;
        margin-bottom: 12px;
    }

    &_price {
        color: #FF6424;
        font-size: 32px;
        font-weight: 700;
        text-align: right;
        margin-bottom: 20px;
    }

    &_btn {
        width: 100%;
        text-align: center;
        font-size: 20px;
        justify-content: center;
        padding: 15px;
    }

    &_content {
        padding: 0 25px 25px;
    }

    &_agree {
        padding: 25px 30px 0;

        .check {
            align-items: flex-start !important;

            .check_box {
                margin-top: 3px;
            }

            span {
                width: calc(100% - 19px);
            }
        }
    }

    &_inform {
        &_item {
            padding: 30px 0;
            border-bottom: 1px solid #000;

            h4 {
                font-size: 18px;
                margin-bottom: 20px;
                color: $main-deep;
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border: 0;
            }
        }

        &_list {
            li {
                font-size: 18px;
                color: $main-deep;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    &:first-child {
                        font-size: 16px;
                        width: 60%;
                        display: inline-block;
                    }
                }
            }
        }

        &_case {
            background-color: $main-color;
            border-radius: 10px;
            padding: 12px 15px;
            color: $main-deep;
            margin-top: 10px;
        }

        &_discount {
            border-bottom: 0;

            .input-common {
                width: calc(100% - 135px);
            }

            .btn-common {
                width: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            a {
                font-size: 14px;
                color: $main-deep;
                text-align: right;
                display: block;
                margin-top: 10px;
            }
        }

        &_check {
            padding-top: 0;
            padding-bottom: 15px;

            .origin {
                font-size: 20px;
                text-decoration: line-through;
            }

            .total {
                font-size: 32px;
                color: #FF6424;
                font-weight: 700;
            }

            .order {
                border: 0;
            }

            .order_content {
                padding: 0;
            }
        }
    }

    @media screen and (max-width: 991px) {
        &_content {
            padding: 0 20px 25px;
        }

        &_agree {
            margin-bottom: 55px;
        }

        // &_inform {
        //     &_check {
        //         .order {
        //             border: 1px solid #000;
        //         }
        //         .order_content {
        //             padding: 0 25px 25px;
        //         }
        //     }
        // }
    }
}

.invoice {
    &_content {
        padding-left: 87px;
        padding-top: 23px;

        .row {
            margin-left: -14px;
            margin-right: -14px;

            >div {
                padding-left: 14px;
                padding-right: 14px;
            }
        }
    }

    &_remark {
        margin-top: 25px;
        color: $main-deep;
        margin-bottom: 45px;
    }

    @media screen and (max-width: 991px) {
        &_content {
            padding-left: 30px;

            .input-common {
                margin-top: 25px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .input-district {
        margin-top: 25px;
    }
}