.list {
  &_content {
      padding-top: 50px;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(0,0,0,0.5);
      &:first-child {
          padding-top: 0;
      }
      &_item {
          margin-bottom: 30px;
          &.item {
              &-favorite {
                  background-color: #E9E9E9;
                  border-radius: 10px;
                  overflow: hidden;
                  position: relative;
                  button {
                      position: absolute;
                      width: 30px;
                      height: 30px;
                      right: 10px;
                      bottom: 10px;
                      border-radius: 5px;
                      cursor: pointer;
                      transition: $transition-main;
                      &:hover {
                          background-color: #ddd;
                      }
                  }
                  .list_content_text {
                      padding: 0 13px;
                  }
              }
              &-record {
                  border-radius: 10px;
                  padding: 20px;
                  background-color: #E9E9E9;
              }
          }
      }
      &_pic {
          width: 45%;
          padding-bottom: calc(45% * 0.56);
          position: relative;
          img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
          }
      }
      &_text {
          width: 55%;
          padding-left: 15px;
      }
      &_title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          margin-bottom: 5px;
      }
      &_date {
          font-size: 14px;
          margin: 0;
      }
      &_inform {
          color: #000;
          font-size: 14px;
          margin: 0;
      }
      &:last-child {
          border-bottom: 0;
          padding-bottom: 100px;
      }
  }
  &-swiper {
      padding: 10px 40px;
      border: 1px solid #000;
      margin: 0;
      &-box {
          width: 70%;
          // width: calc(100% - 150px);
          // width: 700px;
      }
      .swiper-button-next, .swiper-button-prev {
          width: 40px;
          height: 100%;
          background-color: #fff;
          top: 0;
          margin: 0;
          background-image: url(../img/icon_arrow-small-deep.svg);
          background-size: 13px;
         
          background-repeat: no-repeat;
          &::after {
              display: none;
          }
      }
      .swiper-button-next {
          right: 0;
          background-position: 10px center;
      }
      .swiper-button-prev {
          left: 0;
          background-position: calc(100% - 10px) center;
          transform: scaleX(-1);
      }
      .swiper-slide {
          // padding-right: 15px;
          // height: 100%;
          height: auto;
          button {
              width: 100%;
              height: 100%;
          }
      }
      .swiper-wrapper {
          // align-content: streth;
      }
      &-m {
          border: 1px solid #000;
          padding: 20px 12px 25px;
          button {
              width: 100%;
              // margin-top: 12px;
              font-size: 16px;
              padding: 10px;
          }
          .row {
              margin-left: -8px;
              margin-right: -8px;
              >div {
                  padding-left: 8px;
                  padding-right: 8px;
              }
          }
      }
  }
  &_arrange {
      position: relative;
      &_list {
          position: absolute;
          width: 121px;
          top: calc(100% + 5px);
          right: 0;
          border-radius: 10px;
          padding: 12px 20px;
          color: #fff;
          background-color: $main-deep;
          display: none;
          transition: $transition-main;
          z-index: 5;
          li {
              margin-bottom: 8px;
              transition: $transition-main;
              cursor: pointer;
              &:hover {
                  color: $main-color;
              }
              &:last-child {
                  margin-bottom: 0;
              }
          }
      }
      &.active {
          .list_arrange_list {
              display: block;
          }
      }
  }
  &_pagination {
      padding: 50px 0 135px;
  }
}

@media screen and (max-width:991px) {
  .list {
      &-swiper {
          &-box {
              width: 100%;
          }
      }
  }
}   

@media screen and (max-width:767px) {
  .list {
      &_content {
          padding-bottom: 20px;
      }
      &-swiper {
          &-m {
              padding-top: 8px;
          }
      }
  }
}

@media screen and (max-width:575px) {
  .list {
      &_pagination {
          padding-bottom: 68px;
          padding-top: 20px;
      }
      &_content {
          &_title {
              font-size: 14px;
              // -webkit-line-clamp: 1;
          }
          &_item {
              &.item-favorite {
                  button {
                      right: 7px;
                      bottom: 7px;
                      width: 25px;
                      height: 25px;
                      img {
                          width: 18px;
                      }
                  }
              }
          }
      }
  }
}