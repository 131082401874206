@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

$main-color:#FCC800;
$second-color:#F1AE00;
$main-deep:#575757;
$gray-97:#979797;
$transition-main:0.3s;
$font-lato:'Lato', sans-serif;;

body {
    font-family: 'Noto Sans TC', sans-serif;
    color: $main-deep;
    padding: 0;
    height: 0;
    background-color: $main-color;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: $main-deep;
}

main {
    padding-top: 80px;
    background-color: #fff;
}

button, input, select, textarea {
    border: 0;
    background-color: transparent;
    outline: none;
    color: $main-deep;
    padding: 5px 10px;
}

select {
    appearance: none;
}

.position {
    &-relative {
        position: relative;
    }
}

.mb-30 { margin-bottom: 30px; }

.text-decoration-through { text-decoration: line-through; }
.text-center { text-align: center; }

.banner {
    .swiper-wrapper {
        padding-bottom: 60px;
    }
    .swiper-slide {
        width: 100%;
        // padding-bottom: 32.5%;
        padding-bottom: 24.43%;
        position: relative;
        background-color: #DBDBDB;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
            &.banner {
                &_pic {
                    &-m {
                        display: none;
                    }
                }
            }
        }
    }
    .swiper-button-prev, .swiper-button-next {
        width: 22px;
        height: 56px;
        background-image: url(../img/icon_arrow-banner.svg);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0;
        // transform: translateY(calc(-50% - 30px));
        &::after {
            display: none;
        }
    }
    .swiper-button-prev {
        // left: 30px;
        left: 10%;
        transform: translateY(calc(-50% - 30px))
    }
    .swiper-button-next {
        transform: translateY(calc(-50% - 30px)) scaleX(-1);
        // right: 30px;
        right: 10%;
    }
    .swiper-pagination-bullets {
        bottom: 20px !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 43px;
        .swiper-pagination-bullet {
            width: 18px;
            height: 18px;
            background-color: #BFBFBF;
            margin: 0 7px !important;
            opacity: 1;
            &-active {
                background-color: $main-color;
            }
        }
    }
    &-content {
        padding-bottom: 16px;
        &_pic {
            width: 100%;
            padding-bottom: 66%;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: contain;
            }
        }
        &_text {
            margin-top: 10px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }
        .swiper-wrapper {
            height: auto;
        }
        .swiper-button-prev, .swiper-button-next {
            width: 12px;
            height: 24px;
            background-image: url(../img/icon_arrow-banner-small.svg);
            margin: 0;
            &::after {
                display: none;
            }
        }
        .swiper-button-prev {
            left: 15px;
            transform: translateY(calc(-50% - 45px));
        }
        .swiper-button-next {
            right: 15px;
            transform: translateY(calc(-50% - 45px)) scaleX(-1);
        }
        .swiper-pagination-bullets {
            position: static;
            margin-top: 12px;
            margin-bottom: 15px;
            .swiper-pagination-bullet {
                width: 14px;
                height: 14px;
                background-color: #BFBFBF;
                margin: 0 6px !important;
                opacity: 1;
                &-active {
                    background-color: $main-color;
                }
            }
        }
    }
    &-text {
        &-content {
            h3 {
                font-size: 20px;
                color: #000;
                margin: 0;
            }
        }
        &_date {
            font-size: 14px;
            margin: 0 0 8px;
        }
        &_content {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
    &-pictext {
        &_pic {
            width: 15%;
            padding-bottom: calc(15% * 1.23);
            position: relative;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &_text {
            width: 85%;
            padding-left: 18px;
            h3 {
                font-size: 20px;
                color: #000;
                margin: 0;
            }
            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
        }
    }
    &-list {
        .swiper-slide {
            padding-bottom: 28%;
        }
        .swiper-pagination-bullets {
            justify-content: center;
        }
    }
}

.title {
    &-common {
        margin-bottom: 16px;
        img {
            width: 45px;
            margin-right: 11px;
        }
        h2 {
            margin: 0;
            font-size: 26px;
            letter-spacing: 0.05em;
        }
    }
    &_content {
        padding-top: 30px;
        img {
            width: 70px;
            margin-right: 15px;
        }
        h1 {
            font-size: 28px;
            font-weight: 400;
            margin: 0;
        }
        p {
            margin: 0;
            color: #777777;
            display: none;
        }
        &.title-member {
            margin-bottom: 30px;
            p {
                display: block;
            }
        }
    }
    &_article {
        color: #000;
        font-size: 24px;
        margin: 0 0 30px;
        &-second {
            font-size: 20px;
            margin-bottom: 20px;
        }
        &-third {
            font-size: 20px;
            margin-bottom: 20px;
            color: $main-deep;
        }
    }
    &-list {
        margin-bottom: 12px;
        .title-common {
            margin-bottom: 0;
        }
    }
    &-content {
        margin-bottom: 40px;
        &_line {
            width: 15px;
            height: 60px;
            background-color: $main-color;
            border-radius: 3px;
            margin-right: 15px;
        }
        h1 {
            margin: 0;
            font-size: 38px;
            font-weight: normal;
            color: $main-deep;
        }
        @media screen and (max-width: 767px) {
            &_line {
                width: 10px;
                height: 50px;
            }
            h1 {
                font-size: 28px;
            }
        }
    }
}

.btn {
    &-common {
        border-radius: 10px;
        padding: 12px 35px;
        background-color: $main-color;
        display: flex;
        width: fit-content;
        font-size: 14px;
        transition: $transition-main;
        color: #787575;
        cursor: pointer;
        &:hover {
            background-color: $second-color;
        }
    }
    &-border {
        border-radius: 10px;
        padding: 12px 35px;
        display: flex;
        width: fit-content;
        font-size: 14px;
        transition: $transition-main;
        color: $main-deep;
        cursor: pointer;
        border: 1px solid #979797;
    }
    &-gray {
        border-radius: 10px;
        padding: 12px 35px;
        background-color: #ddd;
        display: flex;
        width: fit-content;
        font-size: 14px;
        transition: $transition-main;
        color: $main-deep;
        cursor: pointer;
        &:hover {
            background-color: $second-color;
        }
    }
    &-icontext {
        border-radius: 10px;
        padding: 10px 14px;
        background-color: $main-color;
        display: flex;
        align-items: center;
        transition: $transition-main;
        cursor: pointer;
        width: fit-content;
        img {
            width: 19px;
            margin-right: 10px;
        }
        &:hover {
            background-color: $second-color;
        }
    }
    &-tab {
        background-color: #DDDDDD;
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;
        transition: $transition-main;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        &:hover, &.active {
            background-color: $main-color;
        }
    }
    &-icon {
        width: 42px;
        height: 42px;
        border-radius: 10px;
        background-color: $main-color;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition-main;
        img {
            width: 20px;
            margin: 0 auto;
        }
        &:hover {
            background-color: $second-color;
        }
    }
    &-bg-white {
        background-color: #fff;
    }
    &-exchange {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(calc(-50% + 15px));
    }
    &-logout {
        display: block;
        margin: 27px auto 0;
        padding: 10px;
        width: 150px;
        text-align: center;
        font-size: 16px;
    }
    &-favorite {
        cursor: pointer;
        img {
            width: 12px !important;
            margin: 0 10px 0 0 !important;
            &.icon {
                &-active {
                    display: none;
                }
            }
        }
        &-box {
            position: absolute;
            left: 0;
            top: 50px;
            width: 100%;
            .btn-icontext {
                margin-left: auto;
            }
        }
        &-m {
            img {
                width: 12px;
                &.icon {
                    &-active {
                        display: none;
                    }
                }
            }
            &.active {
                img {
                    &.icon {
                        &-normal {
                            display: none;
                        }
                        &-active {
                            display: block;
                        }
                    }
                }
            }
        }
        &.active {
            img {
                &.icon {
                    &-normal {
                        display: none;
                    }
                    &-active {
                        display: block;
                    }
                }
            }
        }
    }
    &-back {
        img {
            width: 11px;
            transform: scaleX(-1);
        }
    }
    &-hint {
        &_check {
            width: 100%;
            text-align: center;
            justify-content: center;
        }
    }
}

.pagination {
    &_arrow {
        width: 40px;
        height: 40px;
        background-color: $main-color;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 6px;
        transition: $transition-main;
        img {
            width: 10px;
        }
        &.extreme {
            img {
                width: 13.5px;
            }
            &.next {
                transform: scaleX(-1);
            }
        }
        &.normal {
            &.prev {
                transform: scaleX(-1);
            }
        }
        &:hover {
            background-color: $second-color;
        }
    }
    &_num {
        margin: 0 67px;
        li {
            margin: 0 3px;
        }
        a {
            color: #979797;
            font-family: $font-lato;
            font-size: 14px;
            transition: $transition-main;
            &.active, &:hover {
                width: 33px;
                height: 33px;
                border-radius: 50%;
                background-color: #DFDFDF;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
            }
        }
    }
}

.category {
    li {
        margin: 8px 0;
        a {
            color: #d28200;
            &.notfinish {
                color: #4d4d4d;
            }
            &.notworking {
                color: rgb(203, 203, 203);
                &:hover {
                    color: rgb(203, 203, 203);
                }
            }
            &:hover {
                color: $second-color;
            }
        }
    }
    .color-box {
        width: 30px;
        height: 15px;
        margin-right: 10px;
        &.finish {
            background-color: #d28200;
        }
        &.notfinish {
            background-color: #4d4d4d;
        }
        &.notwork {
            background-color: rgb(203, 203, 203);
        }
    }
}

.filter {
    border: 1px solid #000;
    &_item {
        padding: 30px;
        border-bottom: 1px solid #000;
        label {
            color: #3F3F3F;
            margin-bottom: 10px;
            display: block;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}

.input {
    height: 50px;
    width: 100%;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    // background-color: #DDDDDD;
    background-color: #eee;
    position: relative;
    &_label {
        color: #3F3F3F;
        margin-bottom: 10px;
        display: block;
    }
    &_btn {
        width: 50px;
        background-color: $main-color;
        transition: $transition-main;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: $second-color;
        }
        img {
            width: 27px !important;
            margin: 0;
        }
    }
    &_content {
        width: calc(100% - 50px);
        padding: 5px 10px;
        font-size: 14px;
        &::placeholder {
            color: $main-deep;
        }
    }
    &_select {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    &-common {
        border-radius: 10px;
        // background-color: #ddd;
        background-color: #eee;
        padding: 5px 10px;
        width: 100%;
        // height: 100%;
        height: 46px;

        &::placeholder {
            color: $main-deep;
        }
    }
}



@media screen and (max-width:991px) {
    .filter {
        &_item {
            padding: 30px 20px;
        }
    }
}

@media screen and (max-width:767px) {
    .banner {
        .swiper-slide {
            // padding-bottom: 100%;
            img {
                &.banner {
                    &_pic {
                        // display: none;
                        &-m {
                            // display: block;
                        }
                    }
                }
            }
        }
        .swiper-button-next ,.swiper-button-prev {
            width: 15px;
            height: 35px;
        }
        .swiper-button-next {
            right: 10px;
        }
        .swiper-button-prev {
            left: 10px;
        }
        .swiper-wrapper {
            padding-bottom: 40px;
        }
        .swiper-pagination-bullets {
            justify-content: center;
            bottom: 10px;
            padding: 0;
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                margin: 0 3px !important;
            }
        }
        &-content {
            .swiper-pagination-bullets {
                .swiper-pagination-bullet {
                    width: 10px;
                    height: 10px;
                    margin: 0 3px !important;
                }
            }
        }
        &-pictext {
            &_pic {
                width: 25%;
                padding-bottom: calc(25% * 1.23);
            }
            &_text {
                width: 75%;
                p {
                    -webkit-line-clamp: 3;
                }
            }
        }
        &-list {
            .swiper-slide {
                padding-bottom: 56%;
            }
        }
    }
    .btn {
        &-icontext {
            img {
                margin: 0;
            }
            span {
                display: none;
            }
        }
        &-exchange {
            position: static;
            button {
                margin: 34px auto 0;
                padding: 10px 60px;
                span {
                    display: block;
                    margin-left: 9px;
                }
            }
        }
    }
    .title {
        &_content {
            img {
                width: 50px;
            }
            h1 {
                font-size: 24px;
            }
        }
        &_article {
            font-size: 20px;
        }
    }
}

@media screen and (max-width:575px) {
    .pagination {
        // padding-bottom: 68px;
        // padding-top: 20px;
        &_num {
            margin: 0 8px;
        }
    }
    .banner {
        .swiper-slide {
            padding-bottom: 0;
            position: static;
            img {
                &.banner {
                    &_pic {
                        display: none;
                        &-m {
                            display: block;
                            position: static;
                        }
                    }
                }
            }
        }
    }
}