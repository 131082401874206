.lesson {
    &Item {
        background-color: #e6e6e6;
        border-radius: 10px;
        position: relative;

        > a {
            display: block;
            padding: 25px;
        }

        &_pic {
            width: 100%;
            padding-bottom: 56%;
            position: relative;
            margin-bottom: 18px;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }

        &_title {
            color: $main-deep;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-height: 46px;
        }

        &_teacher {
            color: $main-deep;
            font-size: 14px;
            margin-bottom: 25px;
        }

        &_price {
            font-weight: 700;
            font-size: 26px;
            color: $main-deep;
            margin: 0 40px 0 0;

            &.discount {
                color: #ff6424;
            }
        }

        &_origin {
            color: $gray-97;
            font-size: 14px;
            margin: 0;
            text-decoration: line-through;
            transform: translateY(2px);
        }

        &_time {
            color: #777;
            font-size: 14px;
            margin-bottom: 7px;
            height: 20px;
        }

        &_bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &_btn {
            position: absolute;
            bottom: 25px;
            right: 25px;
            display: flex;
            align-items: center;

            .btn-gotoclass {
                width: 70px;
                height: 30px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                margin-right: 10px;
            }
        }

        &_finish {
            margin: 55px 0 0;
            font-size: 20px;
            color: $main-deep;
        }

        @media screen and (max-width: 1199px) {
            margin-bottom: 30px;
        }

        @media screen and (max-width: 767px) {
            a {
                padding: 15px;
            }

            &_top {
                display: flex;
                margin-bottom: 10px;

                &_text {
                    width: 62%;
                    padding-left: 10px;
                }
            }

            &_pic {
                width: 38%;
                padding-bottom: calc(38% * 0.56);
            }

            &_title {
                margin-bottom: 10px;
            }

            &_teacher {
                margin-bottom: 0;
            }

            &_time {
                margin: 0;
            }

            &_price {
                margin-right: 10px;
                font-size: 22px;
            }

            .btn-favorite-m {
                right: 15px;
                bottom: 15px;
            }
        }
    }

    &_listItem {
        padding-top: 40px;
        padding-bottom: 50px;
        border-bottom: 1px solid #000;

        &:first-child {
            padding-top: 12px;
        }

        &:last-child {
            padding-bottom: 100px;
            border: 0;
        }

        @media screen and (max-width: 1199px) {
            .row {
                > div {
                    &:last-child {
                        display: none;
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            .row {
                > div {
                    &:last-child {
                        display: block;
                    }
                }
            }
        }
    }

    &_banner {
        width: 100%;
        padding-bottom: 34.72%;
        position: relative;
        background-color: #ededed;

        &_inner {
            width: 100%;
            max-width: 1280px;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }

        &_content {
            position: relative;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        &_pic {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
        }

        &_mask {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.6);
            position: absolute;
        }

        &_text {
            color: #fff;
            position: absolute;
            width: 45%;
            left: 45px;
            bottom: 45px;

            h1 {
                font-size: 32px;
                margin-bottom: 15px;
                font-weight: normal;
                letter-spacing: 0.05em;
            }
        }

        &_teacher {
            font-size: 20px;
            margin-bottom: 20px;
        }

        &_intro {
            font-size: 18px;
            margin: 0;
            letter-spacing: 0.05em;
            text-align: justify;
        }

        &_video {
            color: #fff;
            position: absolute;
            right: 17%;
            bottom: 20%;
            cursor: pointer;

            img {
                width: 100px;
                display: block;
                margin: 0 auto 15px;
            }

            p {
                text-align: center;
                margin: 0;
            }
        }

        @media screen and (max-width: 1199px) {
            &_text {
                width: 60%;
            }

            &_video {
                right: 10%;
                bottom: 30%;
            }
        }

        @media screen and (max-width: 991px) {
            padding-bottom: 0;

            &_inner {
                position: static;
                transform: none;
            }

            &_content {
                z-index: 0;
                padding: 30px;
            }

            &_mask {
                z-index: -1;
            }

            &_text {
                position: static;
                z-index: 1;
                width: 100%;

                h1 {
                    font-size: 24px;
                }
            }

            &_teacher {
                font-size: 18px;
            }

            &_intro {
                font-size: 16px;
            }

            &_video {
                position: static;
                width: fit-content;
                margin-left: auto;
                margin-bottom: 43vw;

                img {
                    width: 50px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }

    &_title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &_line {
            width: 10px;
            height: 50px;
            border-radius: 3px;
            background-color: $main-color;
            margin-right: 15px;
        }

        h2 {
            font-size: 28px;
            margin: 0;
            font-weight: normal;
            color: $main-deep;
        }
    }

    &_content {
        margin-top: 50px;
        position: relative;
        padding-bottom: 60px;

        &_item {
            margin-bottom: 55px;
        }

        .btn-favorite {
            position: absolute;
            top: 5px;
            right: 20px;
        }

        @media screen and (max-width: 767px) {
            &_item {
                margin-bottom: 35px;
            }

            .btn-favorite {
                img {
                    margin: 0 !important;
                }
            }
        }
    }

    &_video {
        width: 100%;
        padding-bottom: 56.25%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;

        iframe,
        video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &_inform {
        background-color: #eee;
        padding: 40px 90px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        h3 {
            font-size: 16px;
            font-weight: normal;
            color: $gray-97;
            margin-bottom: 10px;
            letter-spacing: 0.05em;
        }

        p {
            font-size: 32px;
            font-weight: 500;
            margin: 0;
            letter-spacing: 0.05em;
            color: $main-deep;

            .text-small {
                font-size: 20px;
                font-weight: normal;
            }
        }

        @media screen and (max-width: 1199px) {
            padding: 40px;
        }

        @media screen and (max-width: 767px) {
            padding: 15px 30px;

            &_item {
                &.openDate {
                    width: 100%;
                    margin-bottom: 45px;
                }
            }
        }

        @media screen and (max-width: 365px) {
            padding: 15px;

            p {
                font-size: 30px;
            }
        }
    }

    &_reciprocal {
        background-color: #eee;
        padding: 40px 90px;
        border-radius: 10px;

        h3 {
            font-size: 16px;
            font-weight: normal;
            margin: 0;
            width: 110px;
            color: $gray-97;
            letter-spacing: 0.05em;
        }

        p {
            font-size: 20px;
            font-weight: 500;
            margin: 0 0 0 35px;

            .highlight {
                font-size: 32px;
                font-weight: 500;
                color: #ff6424;
                letter-spacing: 0.05em;
            }
        }

        @media screen and (max-width: 767px) {
            padding: 15px 30px 40px;

            h3 {
                margin-bottom: 5px;
            }

            p {
                margin-left: 0;
            }
        }

        @media screen and (max-width: 365px) {
            padding: 15px 15px 40px;

            p {
                font-size: 18px;

                .highlight {
                    font-size: 30px;
                }
            }
        }
    }

    &_tab {
        margin-bottom: 30px;

        > li {
            margin: 0 25px;
            width: 100px;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-size: 22px;
            color: $gray-97;
            cursor: pointer;
            transition: 0.3s;

            &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 10px;
                border-radius: 3px;
                background-color: $main-color;
                left: 0;
                bottom: -5px;
                transition: 0.3s;
                opacity: 0;
            }

            &.active,
            &:hover {
                color: #000;

                &::after {
                    opacity: 1;
                }
            }
        }

        &_intro {
            background-color: #eeeeee;
            border-radius: 20px;
            padding: 65px 40px;

            img {
                max-width: 100%;
                margin-bottom: 40px;
            }
        }

        &_content {
            display: none;

            &.active {
                display: block;
            }
        }

        @media screen and (max-width: 575px) {
            > li {
                width: 20%;
                margin: 0;
                font-size: 20px;
            }

            &_intro {
                padding: 30px 20px;

                img {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &_collapse {
        background-color: #eeeeee;
        border-radius: 20px;

        .ant-collapse {
            border: 0;

            .ant-collapse-expand-icon {
                display: none !important;
            }

            .ant-collapse-header {
                padding: 0 !important;
                color: #4a4a4a;
            }

            .ant-collapse-item {
                border-radius: 0 !important;
                padding: 25px 40px 25px 60px;
                border-bottom: 1px solid #cdcdcd;

                &:last-child {
                    border: 0;
                }
            }

            .ant-collapse {
                border: 0 !important;
                background-color: transparent !important;
            }

            .ant-collapse-content {
                background-color: transparent !important;
                border: 0 !important;
                color: #777 !important;
            }

            .ant-collapse-content-box {
                padding: 0 !important;
            }
        }

        &_show {
            cursor: pointer;

            &.active {
                .lesson_collapse_arrow {
                    transform: rotate(180deg);
                }
            }
        }

        &_arrow {
            width: 25px;
            transition: 0.3s;

            img {
                width: 100%;
            }
        }

        &_hide {
            padding-top: 15px;

            &_item {
                display: flex;
                align-items: center;
            }
        }

        @media screen and (max-width: 767px) {
            .ant-collapse {
                .ant-collapse-item {
                    padding: 25px;
                }
            }
        }
    }

    &_chapter {
        &_show {
            .lesson_chapter_title {
                color: #4a4a4a;
                font-size: 20px;
                font-weight: normal;
            }

            .lesson_chapter_finish {
                font-size: 18px;
                color: $main-deep;
            }
        }

        &_hide {
            &_item {
                padding: 10px 0;

                .lesson_chapter_title {
                    padding-left: 40px;
                    color: #777;
                    font-weight: normal;
                    display: flex;
                    font-size: 16px;
                    padding-right: 10px;

                    &_num {
                        width: 45px;
                    }

                    &_main {
                        width: calc(100% - 45px);
                        padding-left: 15px;
                    }
                }

                .lesson_chapter_time {
                    color: #777;
                    font-size: 16px;
                }

                .lesson_chapter_finish {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 30px;
                    }
                }
            }
        }

        &_title {
            width: calc((100% - 25px) * 0.6);
        }

        &_time {
            width: calc((100% - 25px) * 0.2);
            text-align: center;
            font-size: 18px;
        }

        &_finish {
            width: calc((100% - 25px) * 0.2);
            text-align: center;
        }

        @media screen and (max-width: 767px) {
            &_show {
                .lesson_chapter_finish {
                    display: none;
                }

                .lesson_chapter_time {
                    display: none;
                }

                .lesson_chapter_title {
                    width: calc(100% - 40px);
                }
            }

            &_hide {
                &_item {
                    padding: 5px 55px 5px 10px;

                    .lesson_chapter_finish {
                        display: none;
                    }

                    .lesson_collapse_arrow {
                        display: none;
                    }

                    .lesson_chapter_title {
                        width: 90%;
                        padding-left: 0;

                        &_num {
                            width: 45px;
                        }

                        &_main {
                            width: calc(100% - 45px);
                            padding-left: 0;
                        }
                    }

                    .lesson_chapter_time {
                        width: 10%;
                        text-align: right;
                    }
                }
            }

            &.my {
                .lesson_chapter_hide_item {
                    padding-left: 10px;
                    padding-right: 20px;

                    .lesson_chapter_finish {
                        display: flex;
                        width: 50px;
                        justify-content: flex-end;
                    }

                    .lesson_chapter_time {
                        width: 50px;
                    }

                    .lesson_chapter_title {
                        width: calc(100% - 100px);
                    }
                }
            }
        }

        @media screen and (max-width: 450px) {
            &_hide {
                &_item {
                    padding-right: 45px;
                }
            }
        }
    }

    &_qa {
        &_title {
            width: calc(100% - 25px);
            padding-right: 10px;
            font-size: 20px;
            font-weight: normal;
            color: #4a4a4a;
        }

        &_hide {
            color: #777;
            line-height: 2.3;
            font-size: 16px;
            letter-spacing: 0.05em;
            padding-left: 30px;
            padding-right: 50px;

            a {
                text-decoration: underline;
            }

            .highlight {
                color: #ff6424;
            }
        }
    }

    &_message {
        background-color: #eeeeee;
        border-radius: 20px;
        padding: 30px 30px 40px;
        margin-bottom: 30px;
        position: relative;

        &_btn {
            position: absolute;
            top: 35px;
            right: 30px;
        }

        .btn-common {
            color: $main-deep;
        }

        .btn-big {
            width: 200px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
        }

        .btn-small {
            width: 87px;
            height: 42px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &_head {
            margin-bottom: 40px;
        }

        &_name {
            display: flex;

            // align-items: center;
            &_pic {
                width: 43px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: url(../img/icon_yellow-bg.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                span {
                    color: $main-deep;
                    font-weight: 700;
                    font-size: 30px;
                    transform: translateY(-2px);
                }
            }

            &_text {
                padding-left: 11px;
                transform: translateY(5px);

                p {
                    font-size: 24px;
                    font-weight: 500;
                    color: #4a4a4a;
                    margin: 0;
                }

                span {
                    font-size: 16px;
                    color: $gray-97;
                    white-space: nowrap;
                }
            }
        }

        &_content {
            p {
                margin: 0;
                color: #777;
            }
        }

        &_input {
            border-color: $gray-97;
            border-radius: 0;
            outline: none;
            padding: 10px;
            font-size: 16px;
            width: 100%;
            background-color: #fff;
            border: 1px solid $gray-97;
        }

        &_reply {
            background-color: #fff;
            border-radius: 20px;
            padding: 30px 25px 20px;
            margin-top: 25px;
            position: relative;

            .row {
                width: 100%;
            }

            .lesson_message_name {
                width: 200px;
            }

            &_content {
                width: calc(100% - 200px);
            }
        }

        @media screen and (max-width: 767px) {
            padding: 30px 20px 50px;

            &_btn {
                position: static;
                margin: 30px auto 0;
                justify-content: center;
            }

            &_reply {
                .lesson_message_name {
                    width: 100%;
                }

                &_content {
                    margin-top: 25px;
                    width: 100%;
                }
            }
        }
    }

    &_anno {
        padding: 35px 65px 25px;
        border-radius: 20px;
        background-color: #eeeeee;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        &_title {
            margin: 0;
            font-size: 24px;
            color: #4a4a4a;
            font-weight: 500;
        }

        &_time {
            color: #777;
            margin-bottom: 15px;
        }

        &_content {
            color: #777;
            line-height: 1.6;
            // letter-spacing: 0.05em;
        }

        @media screen and (max-width: 767px) {
            padding: 35px 30px;
            margin-bottom: 20px;
        }
    }

    &_teacher {
        .row {
            margin-left: -20px;
            margin-right: -20px;

            > div {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        &_item {
            background-color: #eeeeee;
            border-radius: 10px;
            padding: 25px 25px 10px;
        }

        &_pic {
            width: 100%;
            padding-bottom: 90%;
            position: relative;
            margin-bottom: 30px;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }

        &_name {
            font-size: 24px;
            font-weight: 500;
            color: $main-deep;
            margin-bottom: 15px;
        }

        &_intro {
            color: $main-deep;
            margin: 0;
        }

        @media screen and (max-width: 767px) {
            .row {
                margin-left: 0;
                margin-right: 0;

                > div {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &_item {
                margin-bottom: 40px;
                padding: 20px 20px 40px;
            }
        }
    }

    &_case {
        margin-top: 70px;
        padding-left: 15px;

        .lesson_title {
            margin-bottom: 20px;
            display: none;

            h2 {
                color: #fff;
            }
        }

        .row {
            > div {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &_item {
            background-color: #eeeeee;
            border-radius: 20px;
            overflow: hidden;

            button {
                width: 100%;
                font-size: 20px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &_title {
            background-color: #e6e6e6;
            width: 100%;
            font-size: 20px;
            margin: 0;
            color: $main-deep;
            text-align: center;
            font-weight: normal;
            padding: 10px;
        }

        &_main {
            padding: 20px 24px;
        }

        &_pic {
            width: 100%;
            padding-bottom: 32%;
            position: relative;
            box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
            margin-bottom: 22px;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }

        &_origin {
            font-size: 20px;
            text-decoration: line-through;
            margin: 0;
            color: $gray-97;
        }

        &_price {
            font-size: 42px;
            color: #ff6424;
            font-weight: 600;
            margin: 0;

            &.no_discount {
                color: #000000;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        &_time {
            color: $main-deep;
            margin-bottom: 40px;
        }

        &_intro {
            color: $main-deep;
            margin-bottom: 15px;
            font-size: 18px;
        }

        @media screen and (max-width: 991px) {
            position: fixed;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.7);
            padding: 20px 20px 120px;
            z-index: 10;
            top: -70px;
            left: 0;
            overflow-y: auto;
            display: none;

            .lesson_title {
                display: flex;
            }

            &.open {
                display: block;
            }
        }

        @media screen and (max-width: 575px) {
            &_main {
                padding: 20px;
            }
        }
    }
}

.btn-seecase {
    width: 100%;
    height: 62px;
    font-size: 20px;
    color: $main-deep;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 35px;
    margin-top: -20px;
}

.cartItem {
    position: relative;
    background-color: #eeeeee;
    border-radius: 20px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    a {
        padding: 20px;
        position: relative;
        display: block;
    }

    &_pic {
        width: 30%;
    }

    &_text {
        width: 70%;
        padding-left: 20px;
    }

    &_discountName {
        margin-left: 20px;
        color: $main-deep;
    }

    &_bottom {
        position: absolute;
        bottom: 20px;
        left: calc(((100% - 40px) * 0.3) + 40px);
    }

    &_btn {
        position: absolute;
        display: flex;
        bottom: 20px;
        right: 20px;
    }

    &_cross {
        margin-left: 10px;
    }

    .lessonItem {
        &_pic {
            margin: 0;
        }

        &_title {
            -webkit-line-clamp: 1;
            margin-bottom: 5px;
            height: auto;
        }

        &_time {
            margin-bottom: 0;
        }

        &_price {
            margin-right: 5px;
        }
    }

    @media screen and (max-width: 1199px) {
        &_bottom {
            position: static;
            margin-top: 10px;
        }

        &_title {
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: 767px) {
        &_pic {
            .lessonItem_pic {
                width: 100%;
                padding-bottom: 56%;
            }
        }

        &_text {
            padding-left: 10px;
        }

        .btn-icon {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            cursor: pointer;

            img {
                width: 13px;
            }

            &.btn-favorite-m {
                img {
                    width: 10px;
                }
            }
        }
    }

    @media screen and (max-width: 575px) {
        &_discountName {
            display: none;
        }
    }
}

.classroom {
    &_video {
        width: 100%;
        padding-bottom: 48%;
        position: relative;

        > iframe,
        > video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: contain;
        }
    }

    &_content {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 20px 60px;

        &_title {
            font-size: 32px;
            font-weight: normal;
            color: $main-deep;
            margin-bottom: 60px;
        }
    }

    &_chapter {
        // display: flex;
        transition: 0.3s;

        &_btn {
            background-color: $main-deep;
            border-radius: 10px 0 0 10px;
            padding: 13px 15px 45px;
            color: #fff;
            cursor: pointer;
            height: fit-content;
            position: fixed;
            top: 80px;
            right: 0;
            z-index: 10;
            transition: 0.3s;

            img {
                width: 13px;
                margin: 0 auto 15px;
                display: block;
                transition: 0.3s;
            }

            p {
                margin: 0;
                font-size: 18px;
                font-weight: 600;
            }
        }

        &_content {
            height: calc(100vh - 80px);
            width: 400px;
            background-color: #eeeeee;
            overflow-y: scroll;
            position: fixed;
            top: 80px;
            right: -400px;
            z-index: 10;
            transition: 0.3s;

            h2 {
                color: #4a4a4a;
                margin-bottom: 8px;
                font-size: 18px;
                font-weight: normal;
            }

            ul {
                li {
                    padding-left: 10px;

                    a {
                        display: flex;
                        align-items: center;
                        // justify-content: space-between;
                        padding: 7px 10px;
                        color: #777;
                        border-radius: 10px;
                        transition: 0.3s;
                        border: 2px solid transparent;

                        &:hover {
                            border-color: $main-color;
                        }

                        &.active {
                            border-color: $main-color;
                            background-color: $main-color;
                            color: $main-deep;
                        }
                    }
                }
            }
        }

        &_time {
            width: 50px;
        }

        &_name {
            width: calc(100% - 50px);
            padding-right: 10px;
        }

        &_item {
            padding: 20px 20px 30px;
            border-bottom: 1px solid #bbb;
        }

        &.active {
            right: 0;

            .classroom_chapter_content {
                box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.25);
                right: 0;
            }

            .classroom_chapter_btn {
                right: 400px;

                img {
                    transform: scaleX(-1);
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        &_content {
            padding: 35px 20px 55px;

            &_title {
                font-size: 20px;
                margin-bottom: 30px;
            }

            .lesson_title {
                margin-bottom: 30px;
            }
        }

        &_chapter {
            // top: calc(50px + 48vw);
            // display: block;
            &_btn {
                top: calc(50px + 48vw);
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;

                img {
                    margin: 0;
                }

                p {
                    display: none;

                    br {
                        display: none;
                    }
                }
            }

            &_content {
                width: 100%;
                right: -100vw;
                top: calc(100px + 48vw);
                height: calc(100vh - 48vw - 100px);
            }

            &.active {
                .classroom_chapter_btn {
                    width: 100%;
                    border-radius: 0;
                    padding: 0 20px;
                    right: 0;
                    justify-content: flex-start;

                    img {
                        margin-right: 25px;
                    }

                    p {
                        display: block;
                    }
                }
            }
        }
    }
}

.qa {
    padding-top: 50px;
    padding-bottom: 90px;

    &_content {
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
        padding: 55px 20px 70px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;

        &_item {
            margin-bottom: 50px;

            h2 {
                font-size: 20px;
                font-weight: 600;
                color: $main-deep;
                margin-bottom: 30px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding-top: 20px;
        padding-bottom: 80px;

        &_content {
            padding: 25px 0 50px;

            &_item {
                margin-bottom: 60px;

                h2 {
                    margin-bottom: 25px;
                }
            }
        }
    }
}

.classroom-content-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.classroom_content_title {
    margin: 0;
}

.tellypen-download-btn {
    border: none;
    background: none;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .classroom-content-header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    .tellypen-download-btn {
        margin-top: 0;
        margin-bottom: 0;
    }
}
