// 套件修改
// popup
.popup-overlay {
    background: rgba(0,0,0,.5) !important;
}
.popup-content {
    position: fixed !important;
    width: auto !important;
    border-radius: 10px;
    left: 50% !important;
    top: 30px !important;
    transform: translateX(-50%);
    padding: 0 20px !important;
    width: 100% !important;
    background: transparent;
    box-shadow: none !important;
    border: 0;
    >div {
        &:first-child {
            display: none;
        }
    }
}

// bootstrap
@media screen and (min-width:1400px) {
    .container {
        max-width: 1300px;
    }
}